/**
 * Cookie notice
 */

#cookie-notice {

  background-color: #222 !important;
  .cookie-notice-container {
    min-width: 0;
    margin: auto;
    @include container-fixed;
    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
      width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
      width: $container-lg;
    }
  }
  #cn-notice-text {}
  #cn-accept-cookie {
    background: white;
    padding: 6px 10px;
    border-radius: 3px;
  }
}