.m {
  &t, &y, &a{
    &-0{ margin-top: 0; }
    &-1{ margin-top: $base-spacing * .25; }
    &-2{ margin-top: $base-spacing * .5; }
    &-3{ margin-top: $base-spacing * .75; }
    &-4{ margin-top: $base-spacing ; }
    &-5{ margin-top: $base-spacing * 1.5; }
    &-x2{ margin-top: $base-spacing * 2; }
    &-6{ margin-top: $base-spacing * 3; }
    &-7{ margin-top: $base-spacing * 6; }
  }
  &b, &y, &a{
    &-0{ margin-bottom: 0; }
    &-1{ margin-bottom: $base-spacing * .25; }
    &-2{ margin-bottom: $base-spacing * .5; }
    &-3{ margin-bottom: $base-spacing * .75; }
    &-4{ margin-bottom: $base-spacing ; }
    &-5{ margin-bottom: $base-spacing * 1.5; }
    &-x2{ margin-bottom: $base-spacing * 2; }
    &-6{ margin-bottom: $base-spacing * 3; }
    &-7{ margin-bottom: $base-spacing * 6; }
    &-md {
      @media (min-width: 992px) {
        &-0{ margin-bottom: 0; }
        &-1{ margin-bottom: $base-spacing * .25; }
        &-2{ margin-bottom: $base-spacing * .5; }
        &-3{ margin-bottom: $base-spacing * .75; }
        &-4{ margin-bottom: $base-spacing ; }
        &-5{ margin-bottom: $base-spacing * 1.5; }
        &-x2{ margin-bottom: $base-spacing * 2; }
        &-6{ margin-bottom: $base-spacing * 3; }
        &-7{ margin-bottom: $base-spacing * 6; }
      }
    }
  }
  &l, &x, &a{
    &-0{ margin-left: 0; }
    &-1{ margin-left: $base-spacing * .25; }
    &-2{ margin-left: $base-spacing * .5; }
    &-3{ margin-left: $base-spacing * .75; }
    &-4{ margin-left: $base-spacing ; }
    &-5{ margin-left: $base-spacing * 1.5; }
    &-x2{ margin-left: $base-spacing * 2; }
    &-6{ margin-left: $base-spacing * 3; }
    &-7{ margin-left: $base-spacing * 6; }
  }
  &r, &x, &a{
    &-0{ margin-right: 0; }
    &-1{ margin-right: $base-spacing * .25; }
    &-2{ margin-right: $base-spacing * .5; }
    &-3{ margin-right: $base-spacing * .75; }
    &-4{ margin-right: $base-spacing ; }
    &-5{ margin-right: $base-spacing * 1.5; }
    &-x2{ margin-right: $base-spacing * 2; }
    &-6{ margin-right: $base-spacing * 3; }
    &-7{ margin-right: $base-spacing * 6; }
  }
  &-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

.p {
  &t, &y, &a{
    &-0{ padding-top: 0; }
    &-1{ padding-top: $base-spacing * .25; }
    &-2{ padding-top: $base-spacing * .5; }
    &-3{ padding-top: $base-spacing * .75; }
    &-4{ padding-top: $base-spacing ; }
    &-5{ padding-top: $base-spacing * 1.5; }
    &-x2{ padding-top: $base-spacing * 2; }
    &-6{ padding-top: $base-spacing * 3; }
    &-7{ padding-top: $base-spacing * 6; }
  }
  &b, &y, &a{
    &-0{ padding-bottom: 0; }
    &-1{ padding-bottom: $base-spacing * .25; }
    &-2{ padding-bottom: $base-spacing * .5; }
    &-3{ padding-bottom: $base-spacing * .75; }
    &-4{ padding-bottom: $base-spacing ; }
    &-5{ padding-bottom: $base-spacing * 1.5; }
    &-x2{ padding-bottom: $base-spacing * 2; }
    &-6{ padding-bottom: $base-spacing * 3; }
    &-7{ padding-bottom: $base-spacing * 6; }
  }
  &l, &x, &a{
    &-0{ padding-left: 0; }
    &-1{ padding-left: $base-spacing * .25; }
    &-2{ padding-left: $base-spacing * .5; }
    &-3{ padding-left: $base-spacing * .75; }
    &-4{ padding-left: $base-spacing ; }
    &-5{ padding-left: $base-spacing * 1.5; }
    &-x2{ padding-left: $base-spacing * 2; }
    &-6{ padding-left: $base-spacing * 3; }
    &-7{ padding-left: $base-spacing * 6; }
  }
  &r, &x, &a{
    &-0{ padding-right: 0; }
    &-1{ padding-right: $base-spacing * .25; }
    &-2{ padding-right: $base-spacing * .5; }
    &-3{ padding-right: $base-spacing * .75; }
    &-4{ padding-right: $base-spacing ; }
    &-5{ padding-right: $base-spacing * 1.5; }
    &-x2{ padding-right: $base-spacing * 2; }
    &-6{ padding-right: $base-spacing * 3; }
    &-7{ padding-right: $base-spacing * 6; }
  }
}

.bg {
  &-primary {background-color: $brand-primary;}
  &-black {background-color: $color-black;}
  &-grey-darker {background-color: $color-grey-darker;}
  &-grey-dark {background-color: $color-grey-dark;}
  &-grey {background-color: $color-grey;}
  &-grey-light {background-color: $color-grey-light;}
  &-grey-lighter {background-color: $color-grey-lighter;}
  &-white {background-color: $color-white;}

  &-center { background-position: center; }
  &-cover { background-size: cover; }
}

.color {
  &-black {color: $color-black;}
  &-grey-darker {color: $color-grey-darker;}
  &-grey-dark {color: $color-grey-dark;}
  &-grey {color: $color-grey;}
  &-grey-light {color: $color-grey-light;}
  &-grey-lighter {color: $color-grey-lighter;}
  &-white {color: $color-white;}
}

.inner{
  &-inline-block {
    > * {
      display: inline-block;
    }
  }
}
// Display
.d {
  &-block { display: block; }
  &-inline { display: inline; }
  &-inline-block { display: inline-block; }
  &-none { display: none; }
}

.cursor {
  &-zoom-in { cursor: zoom-in !important; }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}