/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core mixins
@import "../lib/bootstrap/mixins";

// Reset and dependencies
@import "../lib/bootstrap/normalize";
@import "../lib/bootstrap/print";
//@import "../lib/bootstrap/glyphicons";

// Core CSS
@import "../lib/bootstrap/scaffolding";
@import "../lib/bootstrap/type";
@import "../lib/bootstrap/code";
@import "../lib/bootstrap/grid";
@import "../lib/bootstrap/tables";
@import "../lib/bootstrap/forms";
@import "../lib/bootstrap/buttons";

// Components
//@import "../lib/bootstrap/component-animations";
//@import "../lib/bootstrap/dropdowns";
//@import "../lib/bootstrap/button-groups";
//@import "../lib/bootstrap/input-groups";
//@import "../lib/bootstrap/navs";
//@import "../lib/bootstrap/navbar";
//@import "../lib/bootstrap/breadcrumbs";
//@import "../lib/bootstrap/pagination";
//@import "../lib/bootstrap/pager";
//@import "../lib/bootstrap/labels";
//@import "../lib/bootstrap/badges";
//@import "../lib/bootstrap/jumbotron";
//@import "../lib/bootstrap/thumbnails";
//@import "../lib/bootstrap/alerts";
//@import "../lib/bootstrap/progress-bars";
//@import "../lib/bootstrap/media";
//@import "../lib/bootstrap/list-group";
//@import "../lib/bootstrap/panels";
//@import "../lib/bootstrap/responsive-embed";
//@import "../lib/bootstrap/wells";
//@import "../lib/bootstrap/close";

// Components w/ JavaScript
//@import "../lib/bootstrap/modals";
//@import "../lib/bootstrap/tooltip";
//@import "../lib/bootstrap/popovers";
//@import "../lib/bootstrap/carousel";

// Utility classes
@import "../lib/bootstrap/utilities";
@import "../lib/bootstrap/responsive-utilities";
