$font-size-small : $base-font-size * 0.875;
$font-size-p : $base-font-size;
$font-size-h5: $base-font-size * 1.25; //#{power(2)}rem;
$font-size-h4: $base-font-size * 1.375; //#{power(2)}rem;
$font-size-h3: $base-font-size * 1.5; //#{power(2)}rem;
$font-size-h2: $base-font-size * 2.25; //#{power(3)}rem;
$font-size-h1: $base-font-size * 3;
$font-size-h0: $base-font-size * 4.5;

$line-height-p : $base-line-height;

html { font-size: #{$base-rem-value}px }
body {
  font-family: $font-base-stack;
  font-size: $font-size-p ;
  line-height: $line-height-p;
  color: $color-grey-lighter;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h4,.h5,
h4,.h6 {
  font-family: $font-heading-stack;
  //font-weight: 700;
  margin-top: 0;
  @extend .mb-5;
}

h1,.h1 {
  font-size:$font-size-h1;
}
h2,.h2 {
  font-size:$font-size-h2;
}
h3,.h3 {
  font-size:$font-size-h3;
}
h4,.h4 {
  font-size:$font-size-h4 ;
}
h4,.h5 {  font-size:$font-size-h5 ;};
h4,.h6 {  font-size:$font-size-h5 ;}

p, .p {
  font-weight: 400;
  @extend .mb-4;
}

a {}

span {}

small, .small {font-size:$font-size-small;}

b, strong {
  font-weight: 700;
  .p {
    @extend .p;
  }
}

i, em {}

s {}

label, .label {
  font-family: $font-heading-stack;
  font-weight: 700;
  @extend .my-0;
}