/**
* BOOTSTRAP CUSTOM VARIABLES
*/

// Glyphicons font path
$icon-font-path :          "../../fonts/";
// Grid settings
$main-sm-columns :         12;
$sidebar-sm-columns :      4;
// Colors
$brand-primary :           #000;
// Depth
$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-navbar-fixed:      1030;
$zindex-modal-background:  1040;
$zindex-modal:             1050;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
// Borders
$border-radius-base:       3px;

// Basics of a navbar
$navbar-height:            48px;
$navbar-default-color:     #777 !default;
$navbar-default-bg:        #f8f8f8 !default;


/**
* THEMES CUSTOM VARIABLES
*/
$images-path :          "../../images/";
// Colors
$color-white :             #ffffff;
$color-grey-lighter :      #f5f5f5;
$color-grey-light :        #cccccc;
$color-grey :              #999999;
$color-grey-dark :         #666666;
$color-grey-darker :       #222222;
$color-black :             #000000;

$color-blue :              #39a9dc;
$color-blue-dark :         #2a6a98;
$color-yellow :            #fffd52;
$color-purple :            #b60457;
$color-green :             #39b54a;

$color-primary :           $color-yellow;
$color-primary-dark :      $color-primary;
$brand-primary :           $color-primary;
// Glocal scale ratio
$base-rem-value:           10; // px
$base-scale-ratio:         1.8;
$base-size-unit:           $base-scale-ratio * 1rem;
// Font sizing
$base-font-size:           $base-scale-ratio * 1rem;
$base-line-height:         $base-font-size * $base-scale-ratio;
// Base spacing
$base-spacing:             $base-scale-ratio * 1rem;
// Effects
$box-shadow-default:       0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$box-shadow-hover:         0 5px 30px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
// Border
$border-default:           1px solid $color-black;
// Types
$font-base-stack:          'Quattrocento Sans', sans-serif;
$font-heading-stack:       'Quattrocento', serif;
$letter-spacing-default:   0.04em;
// Transitions
$easing-default:           cubic-bezier(.25, .8, .25, 1);
$duration-default:         .4s;
$transition-default:       all $duration-default $easing-default;
// Body

// Header
$header-height:                      180px;
$header-logo-height:                 $header-height / 3;
$header-logo-margin:                 $header-logo-height;

$header-height-is-shrink:            110px;
$header-is-shrink-logo-height:       $header-height-is-shrink / 2;
$header-is-shrink-logo-margin:       $header-is-shrink-logo-height / 2;
// Navbar

// Bootstrap's variables
$navbar-height:                        48px;
$navbar-default-bg:                    $color-black;
$navbar-default-color:                 $color-grey-lighter;
// custom's variables
$navbar-link-size:                     $base-font-size;
$navbar-link-padding:                  $base-spacing;
$navbar-link-margin:                   10px;
$navbar-submenu-bg-color:              $color-black;
$navbar-link-height:                   $navbar-height;
$navbar-link-indicator-color:          $navbar-default-color;
$navbar-link-color:                    $navbar-default-color;
$navbar-link-color-hover:              $color-primary-dark;
$navbar-link-color-active:             $navbar-link-color-hover;
$navbar-submenu-box-shadow:            $box-shadow-default; //0 1px 3px rgba(0, 0, 0, 0.12);
$navbar-submenu-border:                $border-default; //0 1px 3px rgba(0, 0, 0, 0.12);
$navbar-submenu-link-size:             $navbar-link-size;
$navbar-submenu-link-color:            $navbar-link-color;
$navbar-submenu-link-color-hover:      $navbar-link-color-hover;

$navbar-mobile-link-height:            36px;
$navbar-mobile-link-size:              $navbar-link-size;
$navbar-mobile-breakpoint:             $screen-lg;
$navbar-mobile-border:                 $border-default;
$navbar-mobile-bg-color:               $color-black;
$navbar-mobile-overlay-bg-opacity:     .8;
$navbar-mobile-overlay-bg-color:       $color-black;
$navbar-mobile-width:                  260px;

$navbar-toggle-color:                  $color-primary;

// Footer
$footer-bg-color:                      $color-black;

// CTAs
$cta-primary-color:                    $color-white;
$cta-primary-color-hover:              $cta-primary-color;
$cta-primary-bg-color:                 $color-primary;
$cta-primary-bg-color-hover:           $color-primary-dark;
