.card-gallery {
  > a {
    * { transition: $transition-default; }
    color: $color-white;
    h2 {
      i {
        opacity: .2;
        position: relative;
        top: 3px;
      }
    }
    .img-artwork-thumb {
      margin: 8px 0;
    }
    &:hover {
      color: $color-primary;
      h2 {
        i {
          margin-left: 16px;
          opacity: 1;
        }
      }
      //letter-spacing: 0.15rem;
      .img-artwork-thumb {
        //transform: scale(1.05);
        //margin: 8px 6px;
      }
    }
  }
}