@keyframes animation-sample {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animated-sample {
  animation: animation-sample .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}