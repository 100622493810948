// CF7
input.wpcf7-form-control,
textarea.wpcf7-form-control {
  @extend .form-control;
  border-radius: 0;
  background-color: #e3e8f7;
  border: none;
  box-shadow: none;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary !optional;
}

form.wpcf7-form {
  input,
  label,
  textarea {
    width:  100%;
  }
}