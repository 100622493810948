body {
  text-align: center;
  @media (min-width: 768px) {
    text-align: initial;
  }
}

.main-wrap {
  width: 100%;
  overflow: hidden;
}

.content-wrap {
  background-color: $color-black;
}

.main-header {
}

.main-content {
  @extend .pb-6;
}

.main-footer {
}

.main-sidebar {
  @extend .mt-6;
  @extend .mb-4;
}

.post-header {
  @extend .mt-6;
  @extend .mb-4;
  padding: 0;
  border: none;
}

.post-content {
}

.post-footer {
}

.posts-list {
}

#img-404 {
  opacity: .3;
}

#scroll-test, .plx {
  padding-top: 30%;
  width:100%;
  background-image: url('http://placeimg.com/1000/500/animals');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  //will-change: ;
  //&:before {
  //  position: absolute;
  //  top: 0;
  //  left:0;
  //  right: 0;
  //  bottom: 0;
    //background-image: url('http://placeimg.com/1000/500/animals');
    //background-position: 50% 0;
    //background-repeat: no-repeat;
    //background-size: cover;
  //}
}

.plx-test {
  padding-top: 300px;
  width:100%;
  position: relative;
  overflow: hidden;
  .bg {
    z-index: 1;
    position: absolute;
    height: 500px;
    width:100%;
    top:0;
    left: 0;
    background-image: url('http://placeimg.com/1000/500/animals');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
  }
}

.mover {
  position: absolute;
  height: 64px;
  width: 64px;
  background-color: red;
  border:1px solid black;
  border-radius: 50%;
  transition: .2s ease;
  transform: translateX(0);
  //left:0;
  &.left {
    transform: translateX(250px);
    //left:250px;
  }
}

.img-artwork-thumb {
  max-height: 150px;
}

img {
  height: auto;
  max-width: 100%;
}
.wp-caption-text {
  padding: 9px;
  text-align: center;
}