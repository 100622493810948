.single-header {}
.single-content {}
.single-footer {}

.single-artwork {
  .artwork-details {
    p, .p {
      line-height: .5;
    }
  }
  .main-footer {
    transition: opacity 1.5s $easing-default;
  }
  .artwork-canvas-container,
  .artwork-content-container {
    transition: all 1.5s $easing-default;
  }
  @media (min-width: 768px) {
    &.focus-mode {
      #main-header,
      .main-footer{
        opacity: 0;
      }
      .artwork-canvas-container,
      .artwork-content-container {
        transition: all 3s $easing-default;
      }
      .artwork-canvas-container {
        z-index: 100;
        transform: translateX(50%);}
      .artwork-content-container { opacity: 0;}
    }
  }
}

.single-gallery {

  .collection, .story {

  }
}