.main-footer {
  background-color: $footer-bg-color;
  text-align: center;
  @media (min-width: 768px) {
    text-align: initial;
  }
}

.footer-content {
  @extend .py-6;
  @extend .bg-black;
  .widget {
    @extend .mb-6;
    .hide-on-widget {
      display: none;
    }
    @media (min-width: $screen-sm) and (max-width: $screen-md - 1) {
      &:nth-child(2n+3) {
        clear: both;
      }
    }
    @media (min-width: $screen-md) {
      &:nth-child(3n+4) {
        clear: both;
      }
    }
    ul {
      margin-bottom: 0;
    }
    .widget-title {
      color: $color-grey-dark;
      font-size: $font-size-h3;
    }
    .textwidget {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.footer-bottom {
  padding-top: spacing(3);
  padding-bottom: spacing(3);
  background-color: $footer-bg-color;
  .widget-bottom {
    color: $color-grey-dark;
    font-size: $base-font-size * 0.875;
    @media (min-width: 768px) {
      &:last-child {
        text-align: right;
      }
    }
    //p {
    //  color: #999;
    //  margin-bottom: 0;
    //}
  }
  .madeby {
    p {
      padding-right: 32px + 6px;
    }
  }
  #madeby-logo-link {
    position: absolute;
    display: inline-block;
    height: 32px;
    width: 32px;
    text-indent: 100%;
    overflow: hidden;
    margin-left: 6px;
    background-image: url(#{$images-path}logo-master.svg);
  }
}

