// Grid system
.main-content {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}

.main-sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.footer-content {
  .widget {
    @include make-sm-column(6);
    @include make-md-column(4);
  }
}
.footer-bottom {
  .widget-bottom {
    @include make-sm-column(6);
  }
}