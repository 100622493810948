/**
 * Basic spacing
 * -----------------------------------------------------------------------------
 * Multiply $base-spacing by a given coefficient.
 * To use for margin, padding, etc...
 *
 * @param   number      $coef         Multiplier.
 * @return  base-spacing by multiplier : eg. 1.6rem => 16px, eg. 3.2rem => 32px,...
 */

@function spacing($coef){
  @return $coef * $base-spacing;
}

/**
 * Exponential Number
 * -----------------------------------------------------------------------------
 * Raise a number to a given power.
 * To use for types
 *
 * @param   number      $number         Number to raise.
 * @param   number      $power          Power to which it should be raised.
 * @return  number      $exponent       Number raised to exponent.
 */

@function power($power:0, $number:false) {

  $exponent: 1;

  @if $number == false {
    $number: $base-scale-ratio;
  }

  @if $power > 0 {
    @for $i from 1 through $power {
      $exponent: $exponent * $number;
    }
  } @else if $power < 0 {
    @for $i from 1 through $power {
      $exponent: $exponent / $number;
    }
  }

  @return $exponent;
}