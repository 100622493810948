.clear-text-decoration {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.btn {
  color: $color-black;
}
.btn-link {
  color : $color-primary;
  @extend .pa-0;
  transition: padding-left .2s $easing-default;
  &:hover, &:focus {
    color: $color-white;
    @extend .pl-1;
    @extend .clear-text-decoration;
  }
}
.btn-cta {}

