/* Variables */
// Bootstrap default variables
@import "lib/bootstrap/variables";

// Custom variables
@import "common/variables";
@import "common/functions";

/* Libraries */
// Bootstrap
@import "setup/bootstrap-imports";
@import "lib/fontawesome/font-awesome";

/* Custom scss */
@import "common/base";
@import "common/typography";
@import "common/utilities";
@import "common/animations";
@import "common/global";

@import "layouts/grid";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/singles";
@import "layouts/tinymce";
@import "layouts/cards";

@import "components/wp-classes";
@import "components/cookies";
@import "components/comments";
@import "components/buttons";
@import "components/forms";
@import "components/menus";
@import "components/dropdowns";
@import "components/cards";

