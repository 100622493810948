html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $color-black;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  color: $color-primary;
}

a:hover,
a:active {
  color: $color-white;
}

ul {
  margin-bottom: spacing($base-scale-ratio);
  padding-left: 0;
  list-style-type: none;
  li {}
}