.content-wrap {
  margin-top: $header-height;
}

#main-header {
  background-color: $navbar-default-bg;
  position: fixed;
  width: 100%;
  height: $header-height;
  z-index: $zindex-navbar;
  transition: $transition-default;

  a.custom-logo-link {
    height: $header-height;
    float: left;
    transition: $transition-default;
    width: 100%;
    text-align: left;
    @media (min-width: $screen-sm) {
      width: auto;
      text-align: left;
    }
    img {
      height: $header-logo-height;
      margin-top: $header-logo-margin;
      width: auto;
      transition: $transition-default;
    }
  }

  #desktop-nav {
    display: none;
    float: right;
    margin-top: ($header-height - $navbar-height) / 2;
    transition: $transition-default;
    @media (min-width: $navbar-mobile-breakpoint) {
      display: block;
    }
    ul.menu {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      font-size: 0;
      > li {
        display: inline-block;
        position: relative;
        &.btn-cta-menu {
          a {
            color: $cta-primary-color;
            background-color: $cta-primary-bg-color;
            border-radius: $border-radius-base;
            box-shadow: $box-shadow-default;
            transition: $transition-default;
            &:hover {
              color: $cta-primary-color-hover;
              background-color: $cta-primary-bg-color-hover;
              box-shadow: $box-shadow-hover;
            }
            // Hide the :after element used for hovered/active items
            &:before {
              display: none;
            }
          }
        }
        > a {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          margin-left: $navbar-link-margin;
          margin-right: $navbar-link-margin;
          &:last-child {
            margin-right: 0;
          }
          padding-left: $navbar-link-padding;
          padding-right: $navbar-link-padding;
          height: $navbar-height;
          line-height: $navbar-height;
          font-size: $navbar-link-size;
          letter-spacing: $letter-spacing-default;
          color: $navbar-link-color;
          transition: $transition-default;

          // active/hovered indicator
          &:before {
            content: '';
            position: absolute;
            display: block;
            height: 2px;
            width: 0;
            left: $navbar-link-padding;
            bottom: 0;
            background-color: $navbar-link-indicator-color;
            transition: $transition-default;
          }
          &:hover {
            color: $navbar-link-color-hover;
            &:before {
              width: calc(100% - (#{$navbar-link-padding} * 2));
            }
          }

        }
        // Choose which type of item to highlight
        // &.current_page_item:not(.btn-cta-menu), &.current-menu-item:not(.btn-cta-menu), &.current-menu-item:not(.btn-cta-menu) {
        &.current-menu-item:not(.btn-cta-menu) {
          > a {
            color: $navbar-link-color-active;
            &:before {
              width: calc(100% - (#{$navbar-link-padding} * 2));
            }
          }
        }
        // Sub menu
        > ul {
          position: absolute;
          display: block;
          list-style-type: none;
          padding: 0;
          margin-bottom: 0;
          opacity: 0;
          pointer-events: none;
          left: $navbar-link-margin;
          top: $navbar-link-height + 40px;
          border-radius: $border-radius-base;
          background-color: $navbar-submenu-bg-color;
          box-shadow: $navbar-submenu-box-shadow;
          transition: $transition-default;
          > li {
            &:not(:last-child) {
              border-bottom: $navbar-submenu-border;
            }
            > a {
              display: block;
              position: relative;
              vertical-align: middle;
              height: $navbar-link-height;
              line-height: $navbar-link-height;
              padding-left: $navbar-link-padding;
              padding-right: $navbar-link-padding;

              text-transform: none;
              white-space: nowrap;
              font-size: $navbar-submenu-link-size;
              color: $navbar-submenu-link-color;
              transition: $transition-default;
              &:hover {
                padding: 0 10px 0 20px;
                color: $navbar-submenu-link-color-hover;
              }
            }
          }
        }
        &:hover {
          > ul {
            pointer-events: auto;
            opacity: 1;
            top: $navbar-link-height;
            transition: $transition-default;
          }
        }
      }
    }
  }

  &.shrink {
    height: $header-height-is-shrink;
    a.custom-logo-link {
      height: $header-height-is-shrink;
      float: left;
      img {
        height: $header-is-shrink-logo-height;
        margin-top: $header-is-shrink-logo-margin;
      }
    }
    #desktop-nav {
      margin-top: ($header-height-is-shrink - $navbar-height) / 2;
      ul.menu {
        > li {
          > a {
            &:hover {
              &:before {
                //width: calc(100% - (#{$navbar-link-padding} * 2));
              }
            }
          }
          //&.current_page_item:not(.btn-cta-menu), &.current-menu-item:not(.btn-cta-menu), &.current-menu-item:not(.btn-cta-menu) {
          &.current-menu-item:not(.btn-cta-menu) {
            > a {
              &:before {
                //width: calc(100% - (#{$navbar-link-padding} * 2));
              }
            }
          }
        }
      }
    }
  }

  #mobile-nav {
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    @media (min-width: $navbar-mobile-breakpoint) {
      display: none;
    }
    #mobile-menu {
      position: relative;
      height: 100vh;
      padding: 30px 45px;
      width: $navbar-mobile-width;
      background: $navbar-mobile-bg-color;
      border-left: $navbar-mobile-border;
      transform: translateX($navbar-mobile-width);
      transition: $transition-default;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          a {
            display: block;
            padding: 0;
            font-size: $navbar-mobile-link-size;
            line-height: $navbar-mobile-link-height;
            transition: $transition-default;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      @media (min-width: $screen-sm) {
        width: 33vw;
        transform: translateX(33vw);
      }

    }
    .overlay {
      display: block;
      position: absolute;
      pointer-events: none;
      height: 100vh;
      width: 100vw;
      top: 0;
      right: 0;
      opacity: 0;
      transition: $transition-default;
    }

    #nav-toggle {
      display: block;
      position: absolute;
      pointer-events: auto !important;
      left: -60px;
      top: 26px;
      margin: 15px;
      cursor: pointer;
      color: $color-black;
      width: 30px;
      height: 30px;
      transform: rotate(0deg);
      transition: .5s ease-in-out;

      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        background-color: $navbar-toggle-color;

        //Style 1
        //&:nth-child(1) {
        //  top: 0px;
        //}
        //&:nth-child(2),
        //&:nth-child(3) {
        //  top: 10px;
        //}
        //&:nth-child(4) {
        //  top: 20px;
        //}

        //Style 2
        &:nth-child(1) {
          top: 0px;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 10px;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: 20px;
          transform-origin: left center;
        }
      }
    }

    &.open {
      pointer-events: auto;
      #mobile-menu {
        transform: translateX(0);
      }
      .overlay {
        pointer-events: auto;
        opacity: $navbar-mobile-overlay-bg-opacity;
        background-color: $navbar-mobile-overlay-bg-color;
      }
      #nav-toggle {
        span {
          //Style 1
          //&:nth-child(1) {
          //  top: 10px;
          //  width: 0%;
          //  left: 50%;
          //}
          //&:nth-child(2) {
          //  transform: rotate(45deg);
          //}
          //&:nth-child(3) {
          //  transform: rotate(-45deg);
          //}
          //&:nth-child(4) {
          //  top: 10px;
          //  width: 0%;
          //  left: 50%;
          //}

          //Style 2
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 0px;
            left: 8px;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 21px;
            left: 8px;
          }
        }
      }
    }
  }
}